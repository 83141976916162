
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./components/bg.jpg') !important;
  background-repeat:repeat-y;
  background-size:cover;
  font-family: "Montserrat" !important;
  
}


@font-face {
	font-family: 'Montserrat';
	src: url('/public/fonts/montserrat.woff2') format('woff2'),
		 url('/public/fonts/montserrat.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
